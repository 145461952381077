<template>
<div class="position-relative">
  <notification
      v-if="message"
      :successText="!hasError ? message : ''"
      :failureText="hasError ? message : ''"
      @close="handleClose"
  />


  <div v-if="!loading && !canEdit" class="my-2 text-warning">
    You have no permission to make any changes on this page. Please ask to administrator to extend your privileges
  </div>

  <div v-if="changeVisibilityEnabled" class="form-group my-3">
    <label for="pageEnabled" class="form-label no-select">
      <input type="checkbox"
             name="pageEnabled"
             id="pageEnabled"
             :disabled="!canEdit"
             v-model="pageEnabled">
      Page is enabled
    </label>
  </div>

  <template v-if="pageEnabled && !loading">
    <ul class="nav nav-tabs">
      <li class="nav-item" v-for="language in languages" :key = language>
        <a
          href="#"
          class="nav-link"
          @click="activeLanguage = language"
          :class="{active: activeLanguage === language}">
          {{language}}
        </a>
      </li>
    </ul>
  </template>
  <form class="tab-content" ref="formData" v-if="!loading">
    <div v-show="pageEnabled">
      <template v-for="componentItem in componentsList">
        <component
          :is="componentItem.options.type"
          :type="componentItem.type || componentItem.options.type"
          :options="componentItem.options"
          :readonly="componentItem.readonly"
          :index="componentItem.index"
          :language="Object.values(componentItem.options.locale)[0]"
          :id="componentItem.id"
          :name="componentItem.name"
          :languageId="componentItem.language_id"
          :value="componentItem.value"
          @change="(value) => {componentItem.value = value}"
          v-show="showField(componentItem)"
        />
      </template>

    <default-value
      v-if="showDefaultContent"
      :language="activeLanguage"
      :value="targetConditionField.extra"
    />
    </div>

    <div v-if="isConfirmationEmailPage" class="p-2 pre-container rounded">
      <div class="expand-block d-flex flex-row align-items-baseline"
           @click="togglePreviewIcon"
           data-toggle="collapse"
           data-target="#previewSettings">
        <i :class="`mr-2 fa ${previewIcon}`"/>
        Email preview settings
      </div>

      <div id="previewSettings" class="collapse">
        <div class="mt-2 form-row">
          <div class="form-group m-0 col-6">
            <label class="col-form-label">
              Activation Date
              <i class="fa fa-question-circle" data-toggle="tooltip" data-placement="top"
                 :title="`beginning of the day (12 am in ${previewActivationTZ}), it defaults to current time`"/>
            </label>
            <datepicker v-model="previewActivationDate"
                        format="dd/MM/yyyy"
                        input-class="form-control date-picker-input bg-white"
                        placeholder="Choose activation date" />
            <span class="small hint pl-2" v-text="previewActivationTZ"/>
          </div>
          <div class="form-group m-0 col-6">
            <label class="col-form-label">
              Claim Date
              <i class="fa fa-question-circle" data-toggle="tooltip" data-placement="top"
                 :title="`beginning of the day (12 am in ${previewClaimTZ}), it defaults to current time`"/>
            </label>
            <datepicker v-model="previewClaimDate"
                        format="dd/MM/yyyy"
                        input-class="form-control date-picker-input bg-white"
                        placeholder="Choose claim date" />
            <span class="small hint pl-2">{{previewClaimTZ}}</span>
          </div>

          <div class="form-group mb-0 mt-1 col-12">
            <label class="col-form-label">Sponsoring Product</label>
            <select v-model="previewSponsoringProductID" class="form-control">
              <option v-for="product in sponsoringProducts"
                      :key="product.id"
                      :value="product.id"
                      v-text="`${product.description} (${product.upc})`" />
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group my-4">
      <input type="submit"
             @click.prevent="submit"
             :disabled="submitting || !canEdit"
             v-if="componentsList.length > 0"
             class="btn-sm btn-success"
             :value="submitting ? 'Submitting...' : 'Submit'"
      />

      <template v-if="pageEnabled">
        <template v-if="isContactUsPage || isConfirmationEmailPage">
          <input type="submit" @click.prevent="submit" v-if="componentsList.length > 0 && isConfirmationEmailPage || componentsList.length > 0"
                 class="btn-sm btn-info ml-2"
                 :value="submitting ? 'Submitting...' : 'Submit and Send Email'"
                 :disabled="disableForm || submitting || !canEdit"
                 :title="disabledPreviewTitle"/>
        </template>
        <template v-if="isContactUsPage && pageEnabled && previewLink">
          <a class="ml-4"
             target="_blank"
             :href="previewLink">
            Preview Page
            <i class="fa fa-external-link"/>
          </a>
        </template>
      </template>
    </div>
  </form>

  <Loader v-if="loading"/>
</div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';

import imagesUploader from '../../../inputComponents/imageUploader';
import textEditor from '../../../inputComponents/textEditor';
import labeledCheckbox from '../../../inputComponents/labeledCheckbox';
import labeledRadio from '../../../inputComponents/labeledRadio';
import textInput from '../../../inputComponents/textInput';
import textArea from '../../../inputComponents/textArea';

import Loader from '../../shared_components/Loader.vue';
import Notification from "../../../notification.vue";

import defaultValue from "./defaultValue.vue";
import { encrypt } from "../../../../crypto.js";
import getTimezoneList from '../../../../utils/timezoneSelectOptions.js';

import { get } from 'lodash';

export default {
  name: 'pagesSetupForm',
  components:{
    'image_property': imagesUploader,
    'rich_text': textEditor,
    'boolean': labeledCheckbox,
    'radio': labeledRadio,
    'string': textInput,
    'string_multiline': textArea,
    Datepicker,
    Loader,
    Notification,
    defaultValue,
  },
  data: function(){
    return {
      loading: false,
      submitting: false,

      formId: 'landing-page-form',
      componentsList: [],
      languages: [],
      locales: [],
      activeLanguage: undefined,
      activeLocale: undefined,
      localizedComponents: [],
      localesObjects: [],
      disableForm: false,
      disabledPreview: false,

      message: '',
      hasError: false,

      pageType: '',
      isPage: false,
      canEdit: true,
      pageEnabled: true,

      timezoneList: getTimezoneList(),
      previewIcon: 'fa-plus',
      previewActivationDate: null,
      previewActivationTZ: 'Etc/UTC',
      previewClaimDate: null,
      previewClaimTZ: 'Etc/UTC',
      previewSponsoringProductID: null,
      sponsoringProducts: [],
    }
  },
  computed: {
    path: () => window.location.pathname + '.json',
    XCSRFToken: () => document.querySelector('meta[name="csrf-token"]').getAttribute("content"),

    changeVisibilityEnabled() {
      return this.isContactUsPage || (this.isPage && this.pageType !== 'default_privacy_policy');
    },

    isConfirmationEmailPage: function(){
      return !!window.location.href.includes('confirmation_email');
    },

    isContactUsPage: function(){
      return !!window.location.href.includes('contact_us')
    },

    previewLink() {
      let promotionUrl = document.querySelector('meta[name="promotion_url"]');
      promotionUrl = promotionUrl && promotionUrl.content;
      return promotionUrl ? `${promotionUrl}/contact/thank-you?locale=${this.activeLocale}` : null;
    },

    disabledPreviewTitle() {
      return this.disabledPreview ? 'At least one rule needs to be created to send preview emails' : null;
    },

    targetConditionField() {
      const target = 'use_default_branch_page';
      const targetKey = `page.${this.activeLocale}.${target}`;
      return this.componentsList.find(item => item.name === targetKey);
    },

    showDefaultContent() {
      let res = false;

      if (this.targetConditionField && this.targetConditionField.value) {
        res = true;
      }

      return res;
    },
  },
  mounted(){
    this.fetchData();
  },
  watch: {
    activeLanguage: function(){
      this.activeLocale = Object.keys(this.locales.find((locale)=> Object.values(locale)[0] === this.activeLanguage))[0];
    }
  },
  methods: {
    rid() {
      return Math.random().toString(32).substring(2);
    },

    showField(field) {
      let res = this.activeLocale === Object.keys(field.options.locale)[0];

      if (this.targetConditionField) {
        const hiddenFields = ['title', 'html', 'slug'];
        const key = field.name.match(/\w+$/)[0];

        if (hiddenFields.includes(key) && this.targetConditionField.value) {
          res = false;
        }
      }

      return res;
    },

    fetchData () {
      this.loading = true;
      this.$http.get(this.path)
        .then(response => {
          this.updatePageConfiguration(response.data);
        }, (error) => {
          const message = error.request.response ?  JSON.parse(error.request.response).message : '';
          this.showError(message);
        }).finally(() => {
          this.loading = false;
          setTimeout(() => $('[data-toggle="tooltip"]').tooltip())
      });
    },

    appendPropertyField: function(data, component, index) {
      const pageProps = {
        id: 'id',
        type: 'type',
        name: 'name',
        language_id: 'languageId',
      };

      const emptyTypesFor = ['string', 'string_multiline', 'rich_text', 'text', 'boolean'];

      // page general props
      Object.keys(pageProps).forEach((key) => {
        let value = component.componentProperties[pageProps[key]] || '';
        value = (key === 'type' && emptyTypesFor.includes(value)) ? '' : value;

        data.append(`promotion[properties_attributes][${index}][${key}]`, value);
      });

      // one prop value
      let value = component.componentProperties.options.value;

      if (['string', 'string_multiline'].includes(component.componentProperties.type)) {
        // replace platform-dependent line breaks
        value = value.replace(/\r/g, '');
      } else if (component.componentProperties.type === 'rich_text') {
        // decrypt rich_text value
        value = encrypt(value, this.XCSRFToken.substring(0, 32))
      }

      data.append(`promotion[properties_attributes][${index}][value]`, value);
    },

    updatePageConfiguration(data) {
      const { properties, pages, promotion } = data;
      this.languages = [...new Set((properties || pages).map(input => Object.values(input.options.locale)[0]))];
      this.locales = (properties || pages).map(input => input.options.locale);
      this.activeLanguage = typeof this.activeLanguage !== 'undefined' ? this.activeLanguage : this.languages[0];
      this.activeLocale = typeof this.activeLocale !== 'undefined' ? this.activeLocale : Object.keys(this.locales.find((locale) => Object.values(locale)[0] === this.activeLanguage))[0];

      this.pageType = data.page_type;
      this.sponsoringProducts = data.sponsoring_products || [];

      if (promotion) {
        this.previewActivationTZ = promotion.activation_start_timezone;
        this.previewClaimTZ = promotion.claim_end_timezone;
      }

      if (properties && properties.length) {
        this.componentsList = properties;
        this.disabledPreview = data.disabledPreview;
        this.pageEnabledKey = `promotion.${this.pageType}_enabled`;
        this.pageEnabled = get(data, this.pageEnabledKey, true);
      } else if (pages && pages.length) {
        this.canEdit = data.can_edit;
        this.isPage = true;
        this.componentsList = [];

        pages.forEach((page, index) => {
          const locale = Object.keys(page.options.locale)[0]
          let field = {};

          let props = {
            slug: {
              id: page.id,
              title: 'Slug',
              type: 'string',
              readonly: true,
            },
            title: {
              id: this.rid(),
              title: 'Title',
              type: 'string',
            },
            html: {
              id: this.rid(),
              title: 'Content',
              type: 'rich_text',
              hint: 'Use <code>%{expiration_date}</code> as citation',
            },
          };

          if (page.hasOwnProperty('use_default_branch_page')) {
            field = {
              id: this.rid(),
              name: `page.${locale}.use_default_branch_page`,
              value: page.use_default_branch_page,
              type: '',
              language_id: page.language_id,
              readonly: !this.canEdit,
              options: {
                type: 'radio',
                label: 'Custom',
                required: true,
                list: [
                  {label: 'Default privacy policy(from Branch settings)', value: true},
                  {label: 'Set up a custom Privacy Policy', value: false},
                ],
                locale: page.options.locale,
              },
              extra: data.default_branch_pages.find(i => i.language_id === page.language_id),
            };

            this.componentsList.push(field);
          }

          Object.keys(props).forEach(key => {
            field = {
                id: props[key].id,
                name: `page.${locale}.${key}`,
                value: page[key],
                type: "",
                language_id: page.language_id,
                readonly: props[key].hasOwnProperty('readonly') ? props[key].readonly : !this.canEdit,
                options: {
                  label: props[key].title,
                  type: props[key].type,
                  validation: null,
                  required: true,
                  locale: page.options.locale,
                  hint: props[key].hint,
                },
              };

            this.componentsList.push(field);
          })
        });

        this.pageEnabledKey = `pages[0].enabled`;
        this.pageEnabled = get(data, this.pageEnabledKey, true);
      }
    },

    appendPageState(data) {
      const props = {
        [`${this.pageType}_enabled`]: 'pageEnabled',
      };

      Object.keys(props).forEach((key) => {
        data.append(`promotion[${key}]`, this[props[key]]);
      });
    },

    showSuccess(message) {
      this.message = message || "Success";
      this.hideNotification();
    },

    showError(message) {
      this.hasError = true;
      this.message = message || "Something went wrong. Please try again";
      this.hideNotification();
    },

    hideNotification(timeout) {
      setTimeout(() => {
        this.handleClose();
      }, timeout || 5 * 1000);
    },

    handleClose() {
      this.message = null;
      this.hasError = false;
    },

    updateMenuLink() {
      const className = 'text-decoration-line-through';
      const $menuItem = $('.panel.panel-default').find(`a[href$=${this.pageType}`);

      if ($menuItem && $menuItem.length) {
        $menuItem.parent('li').toggleClass(className, !this.pageEnabled);
      }
    },

    updateSidebarItems(pagesData) {
      if (pagesData && pagesData.length) {
         pagesData.forEach((pageData) => {
          const title = pageData.use_default_branch_page ? get(this.targetConditionField, 'extra.title', pageData.title) : pageData.title
          $(`.page-item.page-${pageData.id} a`).text(title);
        });
      }
    },

    normalizedData() {
      let data = new FormData();

      if (!this.isPage) {
        //use "action" flow
        if (this.isConfirmationEmailPage && event.target.value.includes('Email') || this.isContactUsPage && event.target.value.includes('Email')) {
          data.append('preview', 1);
          data.append('active_tab', this.activeLocale);
          if (this.previewActivationDate) {
            data.append('activation_date', this.$moment(this.previewActivationDate).format('YYYY-MM-DD'));
            data.append('activation_tz', this.previewActivationTZ);
          }
          if (this.previewClaimDate) {
            data.append('claim_date', this.$moment(this.previewClaimDate).format('YYYY-MM-DD'));
            data.append('claim_tz', this.previewClaimTZ);
          }
          if (this.previewSponsoringProductID) {
            data.append('sponsoring_product_id', this.previewSponsoringProductID);
          }
        }

        this.$children.forEach((component, index) => {
          const {componentProperties} = component;
          if (componentProperties) {
            if (componentProperties.type === 'ImageProperty') {
              // image only
              if (componentProperties.options.value.length === 0 || !componentProperties.options.value) {
                // remove image
                this.appendPropertyField(data, component, index);
                data.append('promotion[properties_attributes][' + index + "][remove_value]", 1);
              } else if (typeof (componentProperties.options.value) === 'object') {
                // add image if new value
                this.appendPropertyField(data, component, index);
              }
            } else {
              // append other property types
              this.appendPropertyField(data, component, index);
            }
          }
        });

        if (this.isContactUsPage) {
          this.appendPageState(data);
        }
      } else {
        //use "page" flow
        const primaryKey = this.pageType === 'default_privacy_policy' ? 'branch' : 'promotion';
        this.locales.forEach((l, index) => {
          let values = {
            id: null,
          };

          const locale = Object.keys(l)[0];

          this.$children
            .filter(component => component.componentProperties && component.componentProperties.name.includes(`.${locale}.`))
            .forEach((component) => {
              const {componentProperties} = component;

              if (componentProperties.name.includes('.slug')) {
                values = {
                  ...values,
                  id: componentProperties.id,
                  language_id: componentProperties.languageId,
                  enabled: (this.pageType === 'default_privacy_policy') ? true : this.pageEnabled,
                }
              } else if (componentProperties.name.includes('.html')){
                values.html = encrypt(componentProperties.options.value, this.XCSRFToken.substring(0, 32));
              } else {
                const key = componentProperties.name.match(/\w+$/)[0];
                values[key] = componentProperties.options.value
              }
            });

          Object.keys(values).forEach((key) => {
            data.append(`${primaryKey}[pages_attributes][${index}][${key}]`, values[key]);
          });
        });
      }

      return data;
    },

    submit(event) {
      event.preventDefault();

      this.submitting = true;
      this.disableForm = true;
      this.hasError = false;

      this.$http.patch(this.path,
        this.normalizedData(),
        { headers: { 'X-CSRF-Token': this.XCSRFToken }}
      ).finally(() => {
          window.scrollTo(0,0);
          this.disableForm = false;
          this.submitting = false;
      }).then( response => {
        const {data} = response;
        this.updatePageConfiguration(data);

        this.showSuccess(data.message || 'Saved successfully');
        this.updateMenuLink();
        this.updateSidebarItems(data.pages);
      }, (error) => {
        try {
          const message = error.request.response ? JSON.parse(error.request.response).message : '';
          this.showError(message);
        } catch(e) {
          this.showError();
        }
      });
    },

    togglePreviewIcon() {
      this.previewIcon = this.previewIcon === 'fa-plus' ? 'fa-minus' : 'fa-plus';
    },
  }
}
</script>

<style scoped>
.btn.btn-success.ml-3.mt-2{
  width: 150px;
}
input[type="submit"]:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
  opacity: .65;
}
</style>
