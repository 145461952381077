<template>
  <div class="col-lg-7" id="userForm">
    <div class="descContainer" v-if="stepName === 'User Form'">
      <div class="row field-row mb-2 align-items-center">
        <div class="col-lg-4 d-flex align-items-center justify-content-between">
          <div class="field-title">Main Fields</div>
          <i class="fa fa-arrow-left" @click="returnToMainFields"></i>
        </div>
        <div class="col-lg-8">
          <select data-width="100%" @change="setMain" class="mainf">
            <option v-for="fieldOption in mainOptionsWithoutPin"
                    :key="fieldOption"
                    :value="fieldOption">
              &nbsp;{{ fieldTitle(fieldOption) }}
            </option>
            <option class="pin-field">PIN</option>
          </select>
          <select data-width="100%" @change="setMain" class="selectpicker pinOptions"
                  data-none-selected-text="Choose PIN type">
            <option data-hidden="true"></option>
            <option v-for="fieldOption in pinOptions"
                    :key="fieldOption"
                    :value="fieldOption">
              {{fieldTitle(fieldOption)}}
            </option>
          </select>
        </div>
      </div>

      <div class="row field-row mb-2 align-items-center justify-content-end">
        <ul class="fields-list col-lg-8 mb-0">
          <li class="main-field d-flex justify-content-between align-items-center mb-2"
              v-for="field in newOptions.mainFields" :key="field">
            {{ fieldTitle(field) }}
            <i class="fa fa-times" @click="removeField(field)"></i>
          </li>
        </ul>
      </div>

      <div v-show="isCustomPIN">
        <div class="row field-row mb-3 align-items-start">
          <div class="col-lg-4 pt-4">
            <div class="field-title">PIN Length</div>
          </div>
          <div class="col-lg-8 pr-0 mr-n3">
            <div v-for="item in Array(14).fill(0).map((_, idx) => idx + 8)" :key="item" class="d-inline-block mr-2 mb-2 pin-checkbox">
              <input type="checkbox" :id="`pin-length-${item}`" :value="item" v-model="newOptions.pinSettings.length" class="pin-checkbox__check">
              <label :for="`pin-length-${item}`" class="pin-checkbox__label">{{ item }}</label>
            </div>
            <template v-if="!newOptions.pinSettings.length.length">
              <div class="hint smaller text-danger">Please select at least one length</div>
            </template>
          </div>
        </div>
      </div>

      <div class="row field-row mb-2 align-items-center">
        <div class="col-lg-4">
          <div class="field-title">Additional Fields</div>
        </div>
        <div class="col-lg-8">
          <select data-width="100%" @change="setAdditional" class="addf" multiple>
            <option v-for="fieldOption in initialStepData.stepOptions.additionalFields" :key="fieldOption">
              {{fieldOption}}
            </option>
          </select>
        </div>
      </div>
      <template v-if="ddpSumBased">
        <div stepOptions.class="row field-row mb-2 align-items-center">
          <div class="col barcode-container field-title d-flex align-items-center">
            <input name="sumBased" id="sumBased" type="checkbox" v-model="newOptions.sumBased"/>
            <label for="sumBased" class="mb-0 ml-2">Sum-based SN fields</label>
          </div>
        </div>
      </template>
      <template v-if="ocrAvailable">
        <div stepOptions.class="row field-row mb-2 align-items-center">
          <div class="col barcode-container field-title d-flex align-items-center">
            <input name="ocr" id="ocr" type="checkbox" v-model="newOptions.ocr"/>
            <label for="ocr" class="mb-0 ml-2">Barcode recognition</label>
          </div>
        </div>

        <div v-if="newOptions.ocr" stepOptions.class="row field-row mb-2 align-items-center">
          <div class="col barcode-container field-title d-flex align-items-center">
            <input name="ocrFallback" id="ocrFallback" type="checkbox" v-model="newOptions.ocrFallback" :disabled="!newOptions.ocr"/>
            <label for="ocrFallback" class="mb-0 ml-2">OCR Fallback</label>
          </div>
          <div class="col barcode-container field-title d-flex align-items-center">
            <input name="sumBased" id="sumBased" type="checkbox" v-model="newOptions.sumBased"/>
            <label for="sumBased" class="mb-0 ml-2">Sum-based SN fields</label>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
  import {getLabel} from "./fieldsDescription";

  export default {
    name: 'userForm',
    props: ['stepsObj', 'stepOptions', 'blockClass', 'readOnly'],
    data() {
      return {
        showPinField: false,
        stepName: 'User Form',
        newOptions: {
          mainFields: [],
          additionalFields: [],
          ocr: false,
          ocrFallback: true,
          sumBased: false,
          pinSettings: {
            type: null,
            length: [],
          },
        }
      }
    },
    mounted: function () {
      if (Object.keys(this.stepOptions).length > 0) {
        this.newOptions = {
          ...this.newOptions,
          ...this.stepOptions,
          ocr: this.stepOptions.ocr || false,
          ocrFallback: this.stepOptions.hasOwnProperty('ocrFallback') ? this.stepOptions.ocrFallback : true,
        };
      }

      $(this.$el).find('select.mainf').selectpicker();
      $(this.$el).find('select.pinOptions').selectpicker();
      $('.pinOptions, i.fa-arrow-left').hide();
      $(this.$el).find('select.addf').selectpicker('val', this.newOptions.additionalFields);

      $('.pin-field').click(function (event) {
        event.preventDefault();
        event.stopPropagation();
        $('.mainf').hide();
        $('.pinOptions, i.fa-arrow-left').show();
        $('.pinOptions, .pinOptions .dropdown-menu').addClass('show');
      });
    },
    computed: {
      fieldTitle: () => (value) => {
        return getLabel(value);
      },

      additionalFields: function () {
        return this.newOptions.additionalFields
      },
      initialStepData: function () {
        return this.stepsObj.filter(step => {
          return step.blockClass === this.blockClass
        })[0];
      },
      mainOptionsWithoutPin: function () {
        return this.initialStepData.stepOptions.mainFields.filter(option => {
          return !option.includes('PIN')
        })
      },
      pinOptions: function () {
        return this.initialStepData.stepOptions.mainFields.filter(option => {
          return option.includes('PIN')
        })
      },
      step: function () {
        if (this.blockClass.length > 0) {
          return {
            blockClass: this.blockClass,
            options: {
              additionalFields: this.newOptions.additionalFields,
              mainFields: this.newOptions.mainFields,
              ocr: this.ocrAvailable ? this.newOptions.ocr : false,
              ocrFallback: (this.ocrAvailable && this.newOptions.ocr) ? this.newOptions.ocrFallback : false,
              sumBased: ((this.ocrAvailable && this.newOptions.ocr) || this.ddpSumBased) ? this.newOptions.sumBased : false,
              pinSettings: this.newOptions.pinSettings,
            }
          }
        }
      },
      ocrAvailable() {
        const availableOn = ['CODE128', 'CostcoVAN', 'IABWithCheckDigit'];
        return this.newOptions.mainFields.some(field => availableOn.includes(field));
      },
      ddpSumBased() {
        for (const slug of ['CA1124ANY_DDP', 'CA1124DD_DDP', 'CA1224G4_DDP']) {
          if (location.pathname.includes(slug)) {
            return true;
          }
        }
        return false;
      },

      isCustomPIN() {
        return ['PINNumeric', 'PINAlphanumeric'].includes(this.newOptions.pinSettings.type);
      }
    },
    methods: {
      returnToMainFields: function (event) {
        $('.pinOptions, i.fa-arrow-left').hide();
        $('.mainf').show();
      },

      setMain: function (event) {
        const value = $(event.target).val();

        $('.pinOptions, i.fa-arrow-left').hide();
        $('.mainf').show();

        if (value.includes('PIN')) {
          // if PIN field already exists, replace it
          const idx = this.newOptions.mainFields.findIndex(field => field.includes('PIN'));
          if (idx !== -1) {
            this.newOptions.mainFields[idx] = value;
          } else {
            this.newOptions.mainFields.push(value);
          }

          this.newOptions.pinSettings = {
            ...this.newOptions.pinSettings,
            type: value,
          }

          if (!this.isCustomPIN) {
            this.newOptions.pinSettings.length = [];
          }
        } else {
          this.newOptions.mainFields.push(value);
        }

        $(event.target).val('')
      },

      setAdditional: function (event) {
        this.newOptions.additionalFields = [];
        for (let i = 0; i < event.target.selectedOptions.length; i++) {
          this.newOptions.additionalFields.push(event.target.selectedOptions[i].value);
        }
      },

      removeField: function (field) {
        if (!this.readOnly)
          if (field.includes('PIN')) {
            this.newOptions.mainFields = this.newOptions.mainFields.filter(f => !f.includes('PIN'));
            this.newOptions.pinSettings = {
              type: null,
              length: [],
            }
          } else {
            this.newOptions.mainFields = this.newOptions.mainFields.filter(f => f !== field);
          }
      }
    }
  }
</script>

<style scoped>
  .main-field {
    list-style: none;
    font-size: 16px;
  }

  .main-field .fa-times {
    color: lightgray;
    font-size: 12px;
  }

  i.fa-arrow-left {
    color: gray;
    font-size: 18px;
    margin-right: -20px;
    padding: 5px;
  }

  i.fa-arrow-left:hover {
    color: lightgray;
  }
</style>
