<template>
  <div class="card mt-2 overflow-hidden">
    <table class="table table-hover">
      <table-headers :titles="titles.rules" :draggableTableItems="draggableTableItems"></table-headers>
      <draggable element="tbody" v-model="rowsData" :options="{handle: '.fa-unsorted'}" @start="drag=true"
                 @end="dragItemsEnd" v-if="draggableTableItems">
        <table-row
            v-for="rowData in rowsData"
            :key="`${rndId}__${rowData.id}`"
            :rowData="rowData"
            :promotion="promotion"
            :draggableTableItems="draggableTableItems"
            :deletedTableActive="deletedTableActive"
            :allowDeleteAction="allowDeleteAction && !deletedTableActive"
            @edit="(rowId) => $emit('edit', rowId)"
            @remove="(rowId) => $emit('remove', rowId)"
            @dismissWarning="(rowId) => $emit('dismissWarning', rowId)"
        />
      </draggable>
      <tbody v-else>
      <table-row
          v-for="rowData in rowsData"
          :key="rowData.id"
          :rowData="rowData"
          :promotion="promotion"
          :allowDeleteAction="allowDeleteAction && !deletedTableActive"
          @edit="(rowId) => $emit('edit', rowId)"
          @remove="(rowId) => $emit('remove', rowId)"
          @dismissWarning="(rowId) => $emit('dismissWarning', rowId)"
      />
      </tbody>
    </table>
  </div>
</template>

<script>
import draggable from 'vuedraggable'

import titles from './tableHeadersTitles'
import tableHeaders from './tableHeaders'
import tableRow from './tableRow'

export default {
  name: 'infoTable',
  props: ['rowsData', 'draggableTableItems', 'deletedTableActive', 'allowDeleteAction', 'promotion'],
  components: {
    draggable,
    'table-headers': tableHeaders,
    'table-row': tableRow
  },
  data: function () {
    return {
      titles: titles,
      rndId: '',
    };
  },

  mounted() {
    this.updateTitles();
    this.rndId = Math.random().toString(36).substr(2, 9);
  },

  watch: {
    rowsData() {
      this.updateTitles();
      this.rndId = Math.random().toString(36).substr(2, 9);
    }
  },

  computed: {
    presale() {
      return this.rowsData.map(item => item.presale).includes(true);
    }
  },

  methods: {
    updateTitles() {
      const add = 'Street Date, UTC';
      if (this.presale && !this.titles.rules.includes(add)) {
        this.titles.rules.splice(4, 0, add);
      }
    },

    dragItemsEnd: function () {
      this.drag = false;
      let rowItemsOrder = this.rowsData.map(row => row.id);
      this.$emit('dragItemsEnd', rowItemsOrder)
    },
  }
}
</script>

<style scoped></style>
