const labels = {
  CODE128: 'Code 128 (11-digit UPC + VAN19)',
  IABWithCheckDigit: 'IAB (full UPC + VAN19)',
  PINNumeric: 'PIN Numeric',
  PINAlphanumeric: 'PIN Alphanumeric',
};

const deprecated = ['PIN8', 'PIN10', 'PIN11', 'PIN13', 'PIN15', 'PIN16', 'PIN17', 'PIN16 Alphanum']

const getLabel = (label) => {
  return deprecated.includes(label) ? `${label} (Deprecated)` : labels[label] || label;
}

export default labels;

export {
  labels,
  getLabel,
}
